
.y-button {
  cursor: pointer;
  display: inline-block;
  padding: 0 15px;
  height: 32px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  background: #fff;
  box-sizing: border-box;
  .button-box {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
  &.ghost {
    border: 0;
    padding: 0 15px;
  }
}
[type="primary"] {
  background: var(--yy-primary-color);
  color: #fff;
  border-color: currentColor;
}
[type="delete"] {
  background: var(--yy-error-color);
  color: #ffffff;
  border-color: currentColor;
}
[type="active"] {
  color: var(--yy-primary-color);
  img {
    width: 14px;
    height: 14px;
  }
}

// .btn {
//   width: 92px;
//   margin: 0 12px;
//   display: inline-block;
//   cursor: pointer;
//   height: 48px;
//   padding: 0 14px;
//   text-align: center;
//   line-height: 48px;
//   background: #35448f;
//   box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25);
//   border-radius: 8px;
//   font-size: 18px;
//   font-family: PingFang SC-Medium, PingFang SC;
//   font-weight: 500;
//   color: #ffffff;
//   img {
//     vertical-align: sub;
//   }
// }

// .y-btn-primary {
//   background: #157efb;
// }

// .y-btn-text {
//   font-family: "PingFang SC";
//   font-style: normal;
//   font-weight: 500;
//   font-size: 16px;
//   line-height: 24px;
//   height: auto;
//   width: auto;
//   padding: 0;
//   background: transparent;
//   box-shadow: none;
//   border-radius: 0;
//   &-primary {
//     color: #157efb;
//   }
//   &-success {
//     color: #41ca5f;
//   }
//   &-disable {
//     color: #cccccc;
//   }
// }

// .y-btn-loading {
//   opacity: 0.8;
//   pointer-events: none;
// }

// .y-btn-light {
//   height: 48px;
//   background: #ffffff;
//   box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
//   border-radius: 12px;
//   font-family: "PingFang SC";
//   font-style: normal;
//   font-weight: 500;
//   font-size: 18px;
//   color: #2979ff;
// }

@primary-color: #00A4F5;@btn-border-radius-base: 4px;