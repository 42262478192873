.ant-btn {
  border-radius: 4px;
  &.ant-btn-primary {
  }
}

.ant-drawer-footer {
  padding: 17px 24px !important;
}

.ant-table {
  &-thead {
    tr {
      th {
        background-color: #e3eaf1;
        padding: 15px 16px;
        &::before {
          display: none;
        }
      }
    }
  }
  &-tbody {
    tr {
      &:nth-child(2n) {
        td {
          background-color: #f2f9ff;
        }
      }
      td {
        padding: 10px 16px;
        background-color: #fff;
      }
    }
  }
}

// @baseExpandMarginLeft: 15px;

// // .ant-input,
// // .ant-select,
// // .ant-select-selector,
// // .ant-picker-input > input,
// // .ant-input-number-input-wrap > input,
// // .ant-select-show-search.ant-select:not(.ant-select-customize-input)
// //   .ant-select-selector
// //   input {
// //   cursor: pointer !important;
// // }

// // .ant-select-disabled {
// //   cursor: not-allowed !important;
// //   .ant-select-selector {
// //     cursor: not-allowed !important;
// //   }
// // }

// .light-form-wrapper,
// .ant-pagination-options {
//   .ant-input,
//   .ant-input-password,
//   .ant-input-number,
//   .ant-select,
//   .ant-picker,
//   .ant-input-affix-wrapper,
//   .ant-select-selection {
//     // background: var(--yy-gray-color) !important;
//     // border: 0;

//     // &:focus {
//     //   border: 0 !important;
//     //   outline: 0;
//     //   box-shadow: none;
//     // }
//   }

//   .ant-input-number {
//     min-width: 200px;
//   }

//   .ant-input-number-focused {
//     border: 0 !important;
//     outline: 0;
//     box-shadow: none;
//   }

//   .ant-select-open .ant-select-selection {
//     border: 0 !important;
//     outline: 0;
//     box-shadow: none;
//   }

//   .ant-input-group-addon {
//     border: 0;
//   }

//   .ant-input-disabled {
//     color: rgba(0, 0, 0, 0.5) !important;
//     cursor: not-allowed !important;
//   }
// }

// // table

// .ant-table-title {
//   font-size: 18px;
//   font-weight: bold;
// }

// .ant-table-thead > tr > th,
// .ant-table-tbody > tr > td {
//   padding: 10px 16px;
// }

// .ant-table-thead > tr > th {
//   background: #ecf7fe;
//   border-radius: 0px 0px 0px 0px;
//   opacity: 1;
//   border-bottom: 1px solid #d3e5fb;
// }

// .ant-table-tbody > tr > td {
//   border-bottom: 0;
// }

// .ant-table-wrapper:not(.custom-stripe) {
//   .ant-table-tbody {
//     tr {
//       &:nth-child(2n) {
//         // background: #f7f8fa;
//       }
//     }
//   }
// }

// .ant-table-bordered .ant-table-tbody > tr > td {
//   border: 0 !important;
// }

// .striped-row {
//   background: #f7f8fa !important;
//   .ant-table-cell-fix-left {
//     background: #f7f8fa;
//   }
// }

// .search-wrapper {
//   .ant-form-inline {
//     .ant-row {
//       margin-bottom: 15px;
//     }
//   }
// }

// // select
// // .ant-form-item-label > label {
// //   color: #4e5969;
// // }

// .light-form-wrapper .ant-input,
// .light-form-wrapper .ant-input-number,
// .light-form-wrapper .ant-select,
// .light-form-wrapper .ant-select-selection,
// .ant-select:not(.ant-select-customize-input) .ant-select-selector,
// .ant-picker-range {
//   // background: #f7f8fa !important;
//   border: 0;
// }
// // 下拉框/时间选择器，选中时下拉框的样式
// .ant-select-focused:not(.ant-select-disabled).ant-select:not(
//     .ant-select-customize-input
//   )
//   .ant-select-selector,
// .ant-picker-focused {
//   border: 0;
//   box-shadow: none;
// }

// .ant-select-selector {
//   background-color: transparent !important;
// }

// // 下拉框，选中时 选中的样式（部分）
// .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
//   background-color: #f5f5f5;
// }

// // .ant-btn {
// //   outline: 0;
// //   border: 0;
// // }

// // .ant-btn-reset {
// //   border: 0;
// //   background: var(--yy-gray-color);
// //   &:hover {
// //     background: var(--yy-gray-color);
// //   }
// // }

// // .ant-btn.ant-btn-primary {
// //   background: var(--yy-primary-color);
// //   border-radius: 2px 2px 2px 2px;
// // }

// // .ant-btn.ant-btn-primary[disabled] {
// //   background: var(--yy-primary-color);
// //   opacity: 0.5;
// //   color: #fff;
// //   border-radius: 2px 2px 2px 2px;
// // }

// // .ant-btn.ant-btn-text {
// //   color: var(--yy-primary-color);
// // }

// // .ant-btn.ant-btn-text[disabled] {
// //   color: var(--yy-primary-color);
// //   opacity: 0.5;
// // }

// // .ant-btn-primary.ant-btn-background-ghost {
// //   background: none;
// // }

// .ant-select-selection-selected-value {
//   color: #000000;
// }

// .ant-select-arrow {
//   color: #424e66;
// }

// // table
// .ant-table-content {
//   border: 1px solid #f7f8fa;
// }

// .ant-table-thead > tr > th {
//   background: #ecf7fe;
//   border-bottom: 0;
// }

// .ant-table-tbody > tr > td {
//   color: #000;
// }

// .ant-table-thead > tr > th .ant-table-header-column .ant-table-column-title {
//   color: #0077e4;
//   font-size: 14px;
//   font-weight: 500;
// }

// .ant-table-thead
//   > tr
//   > th:not(:last-child):not(.ant-table-selection-column):not(
//     .ant-table-row-expand-icon-cell
//   ):not([colspan])::before {
//   width: 0;
// }

// //表头样式
// .ant-table-thead > tr > th
// // , .ant-table-tbody > tr > td
// {
//   padding: 7px 10px;
//   color: #0077e4;
//   font-size: 14px;
//   font-weight: 500;
// }

// //表格样式
// .ant-table-tbody > tr > td {
//   padding: 7px 10px;
// }
// // table page
// .pagination-box {
//   .ant-pagination-total-text {
//     font-size: 12px;
//     font-weight: 400;
//     color: #1d2129;
//     margin-right: 15px;
//   }

//   .ant-pagination-prev > .ant-pagination-item-link {
//     border: 0;
//     color: #4e5969;
//   }

//   .ant-pagination-next > .ant-pagination-item-link {
//     border: 0;
//     color: #4e5969;
//   }

//   .ant-pagination-item {
//     border: 0;
//     font-size: 14px;
//     font-weight: 400;
//     color: #4e5969;
//   }

//   .ant-pagination-item.ant-pagination-item-active {
//     border: 0;
//     border-radius: 2px 2px 2px 2px;
//     background: #e8f3ff;
//     font-size: 14px;
//     font-weight: 600;
//     color: #165dff;
//   }
//   .ant-pagination-options-quick-jumper {
//     display: none;
//   }
// }

// .ant-upload.ant-upload-select-picture-card {
//   width: 88px;
//   height: 88px;
//   background-color: #ffffff;
//   border: 1px dashed #e5e6eb;
//   margin-right: 12px;
//   margin-bottom: 12px;
//   border-radius: 3px;
// }

// .ant-upload-list-picture-card-container {
//   width: 88px;
//   height: 88px;
//   margin-right: 12px;
//   margin-bottom: 12px;
//   border-radius: 3px;
// }

// .ant-upload-list-picture-card .ant-upload-list-item {
//   width: 88px;
//   height: 88px;
//   padding: 0;
//   margin: 0 8px 8px 0;
//   border-radius: 3px;
// }

// .ant-upload-select-picture-card i {
//   font-size: 16px;
//   color: #d9d9d9;
// }

// .ant-upload-select-picture-card .ant-upload-text {
//   margin-top: 4px;
//   color: #86909c;
//   font-size: 14px;
// }

// .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete {
//   font-size: 24px;
//   width: 24px;
// }

// .ant-upload-select-text {
//   .ant-btn {
//     background: var(--yy-gray-color);
//   }
// }

// .ant-tabs-nav {
//   .ant-tabs-tab,
//   .ant-tabs-tab-active {
//     font-size: 14px;
//     color: #1d2129;
//     font-weight: 500;
//     text-shadow: none;
//   }

//   .ant-tabs-ink-bar {
//     display: none !important;
//   }
// }

// .ant-select-dropdown-menu-item {
//   font-weight: 800;
// }

// .ant-select-dropdown-menu-item:hover:not(
//     .ant-select-dropdown-menu-item-disabled
//   ) {
//   background-color: rgba(242, 243, 245, 1);
// }

// // Modal
// .ant-modal {
//   &-close-x {
//     width: 48px;
//     height: 48px;
//     line-height: 48px;
//   }
//   &-header {
//     border-radius: 8px 8px 0 0;
//     background-color: rgba(236, 247, 254, 1);
//     padding: 12px 24px;
//     border-bottom: 0;
//     .ant-modal-title {
//       font-size: 16px;
//       font-family: PingFang SC-Medium, PingFang SC;
//       font-weight: 800;
//       color: #1d2129;
//       line-height: 24px;
//       text-align: center;
//     }
//   }
//   &-body {
//     .ant-form-item {
//       margin-bottom: 16px;
//       .ant-form-item-children {
//         // display: flex;
//         // align-items: center;
//       }
//       .ant-input-number {
//         width: 100%;
//       }
//       .input-number-suffix {
//         font-size: 14px;
//         font-family: PingFang SC-Regular, PingFang SC;
//         font-weight: 400;
//         color: #4e5969;
//         line-height: 16px;
//         position: absolute;
//         top: 3px;
//         // right: -10px;
//       }
//       .ant-select-selection-selected-value,
//       .ant-input,
//       .ant-input-number-input {
//         font-weight: 500;
//         color: #000000;
//       }
//     }
//     // .ant-form-item.ant-form-item-has-error {
//     //   margin-bottom: 0;
//     // }
//     // .ant-form-item-explain,
//     // .ant-form-item-extra {
//     //   line-height: 16px;
//     // }
//   }
//   &-footer {
//     text-align: center;
//     border-top: 0;
//     padding: 24px 16px;
//     padding-top: 0;
//     border-radius: 0 0 6px 6px;
//     .ant-btn {
//       border: 0;
//       border-radius: 0;
//       background: rgba(242, 243, 245, 1);
//       font-size: 14px;
//       font-family: PingFang SC-Regular, PingFang SC;
//       font-weight: 400;
//       color: #4e5969;
//       line-height: 22px;
//       letter-spacing: -1px;
//     }
//     .ant-btn-primary {
//       background: rgba(0, 119, 228, 1);
//       color: rgba(255, 255, 255, 1);
//     }
//   }
// }

// tr.ant-table-expanded-row td > .ant-table-wrapper {
//   margin-top: -5px;
//   margin-bottom: -5px;
// }

// .ant-table-expand-icon-th {
//   border-right: 0 !important;
// }
// .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
//   background: rgb(0 119 228);
//   border-color: rgb(0 119 228);
//   color: #fff;
// }

// .ant-radio-button-wrapper-checked:not(
//     .ant-radio-button-wrapper-disabled
//   ):hover {
//   background: #2695f0;
//   border-color: #2695f0;
//   color: #fff;
// }

// .light-form-wrapper {
//   .ant-input-affix-wrapper {
//     // background: #f7f8fa !important;
//     border: 0;
//   }
// }

// // input:-webkit-autofill , textarea:-webkit-autofill, select:-webkit-autofill{
// //   -webkit-text-fill-color: #ccc !important;
// //   -webkit-box-shadow: 0 0 0px 1000px transparent  inset !important;
// //   background-color:transparent;
// //   background-image: none;
// //   transition: background-color 50000s ease-in-out 0s;
// // }

// .modal-hide-cancel {
//   .ant-modal-confirm-btns {
//     .ant-btn:not(.ant-btn-primary) {
//       display: none;
//     }
//   }
// }

// .ant-modal-confirm-btns {
//   .ant-btn {
//     &:first-child {
//       background: #f7f8fa !important;
//     }
//   }
// }

// .ant-popover-inner-content {
//   .tags {
//     display: flex;
//     justify-content: center;
//     flex-wrap: wrap;
//     gap: 10px;
//     .tag {
//       background-color: #e3eefa;
//       padding: 3px 5px;
//       border-radius: 4px;
//       margin-bottom: 4px;
//     }
//   }
// }

// //触摸菜单的样式
// .side-menu .ant-menu-inline .ant-menu-item:hover {
//   background-color: #e0effb;
// }

// .ant-popover-buttons {
//   .ant-btn {
//     &:first-child {
//       background: var(--yy-gray-color);
//     }
//   }
// }

// .clickable-row {
//   td {
//     cursor: pointer;
//   }
// }

// // .ant-btn-delete {
// //   color: var(--yy-error-color) !important;
// //   &:hover {
// //     color: var(--yy-error-color) !important;
// //   }
// // }
// // .ant-btn-delete[disabled] {
// //   color: var(--yy-error-color) !important;
// //   opacity: 0.35;
// //   &:hover {
// //     opacity: 0.35;
// //     color: var(--yy-error-color) !important;
// //   }
// // }

// .ant-tree-list {
//   .ant-tree-treenode {
//     width: 100%;
//     align-items: stretch;
//     .ant-tree-node-content-wrapper {
//       flex: 1;
//     }
//   }
// }

// // .ant-tree-title {
// //   width: 100%;
// // }

// .tree-node-row {
//   display: flex;
//   .tree-node-action {
//     display: none;
//   }
//   &:hover {
//     .tree-node-action {
//       display: block;
//     }
//   }
// }

// .ant-tree-treenode-selected {
//   .tree-node-action {
//     display: block;
//   }
// }
